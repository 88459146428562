

import { getPageData } from '@/api';
import { defineComponent, onMounted } from 'vue';
import { setup } from 'vue-class-component';
const pdf = require('@/doc/PdfGen')

export default defineComponent({
  name: 'App',
  setup(){
  }
});
